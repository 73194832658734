
import { makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';

import { 
  BrowserRouter as Router, 
  Switch,
  Route,
} from 'react-router-dom'

import { 
  useSelector
} from 'react-redux'

import {
  AuthenticatedAppBar,
  LoggedOutAppBar,
  DrawerLeft
} from './components/Navigation' 

import { 
  Login,
  Dashboard,
  User,
  Courier,
  Package,
  Support,
} from './pages'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  offset: {
    toolbar: theme.mixins.toolbar

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));


function App() {
  const classes = useStyles();
  const authenticated = useSelector(state => state.authentication.authenticated)
  const drawer = useSelector(state => state.navigation.drawer)

  const loggedIn = () => (
      <div className={classes.root}>
        <CssBaseline />
        <AuthenticatedAppBar/>
        <DrawerLeft />
        <main className={clsx(classes.content, {
            [classes.contentShift]: drawer,
          })}>
          <div className={classes.drawerHeader} />
            <Router>
              <Switch>
                <Route exact path="/" component={Dashboard}/>
                <Route path="/user" component={User}/>
                <Route path="/courier" component={Courier}/>
                <Route path="/package" component={Package}/>
                <Route path="/support" component={Support}/>
              </Switch>
            </Router>
        </main>
      </div>
  )

  const loggedOut = () => (
    <div>
      <CssBaseline />
      <LoggedOutAppBar/>
        <Router>
          <Switch>
            <Route exact path="/">
              <Login/>
            </Route>
          </Switch>
        </Router>
    </div>
  )
    
  return (
    <>
      {authenticated ? loggedIn() : loggedOut()}
    </>
  );
}

export default App;
