import axios from 'axios'
import { HOST } from '../../settings/constant'

export const authServices = {
    login,
    logout
}


async function login(credentials){

    let response = await axios.post(`${HOST}/adminLogin/`,
        credentials,
    )

    if (response.status === 200) {
        let user = response.data
        localStorage.setItem('user', JSON.stringify(user))
        return user
    }else {
        logout()
        return 404
    }
}

function logout(){
    localStorage.removeItem('user')
}
