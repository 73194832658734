import { authServices } from '../services'
import { authConstants} from '../constants'

export const authAction = {
    login,
    logout,
}

function login(credentials) {
    return dispatch => {
        dispatch(request());

        authServices.login(credentials)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: authConstants.LOGIN_REQUEST } }
    function success(user) { return { type: authConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function logout(){
    authServices.logout()
    return { type: authConstants.LOGOUT };
}