import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { 
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@material-ui/core'

import { 
  Link
} from 'react-router-dom'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { useSelector, useDispatch } from 'react-redux';

import { navAction } from '../../redux/action'

import {
  PackageIcon
} from '../Icon'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export function DrawerLeft() {
    const classes = useStyles();
    const drawer = useSelector(state => state.navigation.drawer)
    const dispatch = useDispatch()

    const handleDrawer = () => {
        dispatch( navAction.toggleDrawer(false) )
    };

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem button component="a" href="/" key={"Dashboard"}>
                <ListItemIcon> <DashboardIcon/> </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </ListItem>
              <ListItem button component="a" href="/user" key={"User"}>
                <ListItemIcon> <AccountCircleIcon/> </ListItemIcon>
                <ListItemText primary={"User"} />
              </ListItem>
              <ListItem button component="a" href="/courier" key={"Courier"}>
                <ListItemIcon> <AssignmentIndIcon/> </ListItemIcon>
                <ListItemText primary={"Courier"} />
              </ListItem>
              <ListItem button component="a" href="/package" key={"Package"}>
                <ListItemIcon> <PackageIcon style={{fontSize: "2em"}}/> </ListItemIcon>
                <ListItemText primary={"Package"} />
              </ListItem>
              <ListItem button component="a" href="/support" key={"Support"}>
                <ListItemIcon> <ContactSupportIcon/> </ListItemIcon>
                <ListItemText primary={"Support"} />
              </ListItem>
            </List>
        </Drawer>
    );
}


export default DrawerLeft;