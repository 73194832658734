

export function Support(){

    return (
        <div>
            Support
        </div>
    )

}
