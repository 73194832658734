
import {
    InfoCard
} from '../components/Dashboard'


export function Dashboard(){

    return (
        <div>
            Dashboard
        
            <InfoCard/>
        </div>
    )

}