
import { navConstants } from '../constants'

export const navAction= {
    toggleDrawer
}

function toggleDrawer(open){
    if(open){
        return { type: navConstants.DRAWER_OPEN }
    }else{
        return { type: navConstants.DRAWER_CLOSED }
    }
}