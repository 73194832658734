
import { 
    DataTable
} from '../components/General'

export function User(){

    return (
        <div>
            <DataTable/>
        </div>
    )

}
