import { authConstants } from '../constants'

let user = JSON.parse(localStorage.getItem('user'))
const initialState = user ? { user, authenticated: true } : { };

export function authentication(state = initialState, action){
    switch(action.type){
        case authConstants.LOGIN_REQUEST:
            return {
                loading: true,
                authenticated: false,
            }
        case authConstants.LOGIN_SUCCESS:
            return {
                loading: false,
                authenticated: true,
                user: action.user
            }
        case authConstants.LOGIN_FAILURE:
            return {
                loading: false,
                authenticated: false,
                error: action.error,
            }

        case authConstants.LOGOUT:
            return {}
        default:
            return state
    }
}
