import {Icon} from '@material-ui/core'

import packageSvg from '../../assets/package.svg'

export function PackageIcon(props){
    
    return (
        <Icon {...props}>
            <img alt="packageIcon" src={packageSvg} />
        </Icon>
    )
}