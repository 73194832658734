
import { navConstants } from '../constants'

const initialState = {
    drawer: true, 
}


export function navigation(state = initialState, action){

    switch(action.type){
        case navConstants.DRAWER_OPEN:
            return {
                drawer: true
            }

        case navConstants.DRAWER_CLOSED:
            return { 
                drawer: false
            }

        default: 
            return state 
    }
}