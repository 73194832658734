
import { 
    makeStyles,
    Card, 
    CardContent,
    Typography,
    SvgIcon
} from '@material-ui/core'


import { PackageIcon } from '../Icon/'

import Package from '../../assets/package.svg'

import PeopleIcon from '@material-ui/icons/People';

const cardStyles = makeStyles({
    root: {
        display: 'flex',
        width: 300,
        textAlign: 'center'
    },
    // icon: {
    //     width: 75,
    //     margin: 'auto',
    // },
    iconStyle: {
        fontSize: '5em',
        // color: green[500]
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    }
  });


export function InfoCard(){
    const classes = cardStyles();

    return (
        <Card className={classes.root}>
            {/* <div className={classes.icon}> */}
                <CardContent>
                    <PackageIcon />
                    {/* <PeopleIcon className={classes.iconStyle}/> */}
                </CardContent>
            {/* </div> */}
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography>
                        Hello
                    </Typography>
                    <Typography>
                        Hello
                    </Typography>
                </CardContent>
            </div>
        </Card>
    )
}