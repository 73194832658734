import {
    Paper,
    TextField,
    Grid,
    Button
} from '@material-ui/core'
import { useState } from 'react'

import { useDispatch } from 'react-redux'

import { authAction } from '../../redux/action'

export function Login(){
    const dispatch = useDispatch()
    const [state, setState] = useState({
        email: "",
        password: ""
    })


    const onChange = (event) => {
        let eventID = event.target.id
        let value = event.target.value

        setState({
            ...state,
            [eventID]: value
        })

    }

    const login = () => {
        dispatch(authAction.login(state))
    }

    
    return (
        <div style={{margin: "5%", textAlign: 'center'}}> 
            <Paper style={{width: "500px", margin: 'auto'}} elevation={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div style={{fontSize:"2em"}}> Login </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            id="email"
                            variant="outlined" 
                            label="Email"
                            onChange={onChange}    
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            id="password"
                            variant="outlined" 
                            label="Password"
                            type="password"
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={login}    
                        > 
                            Login 
                        </Button>
                    </Grid>
                </Grid>

            </Paper>
        </div>
    )
}