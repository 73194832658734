import {Icon} from '@material-ui/core'

import wistlySvg from '../../assets/logo.svg'

export function WistlyIcon(props){
    
    return (
        <Icon {...props}>
            <img alt="wistlyIcon" src={wistlySvg} />
        </Icon>
    )
}