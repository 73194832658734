import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools} from 'redux-devtools-extension'

import thunkMiddleWare from 'redux-thunk'
import { createLogger } from 'redux-logger'

import rootReducer from './reducers'

const loggerMiddleWare = createLogger()
const middleware = applyMiddleware(thunkMiddleWare, loggerMiddleWare)

const store = createStore(rootReducer, composeWithDevTools(middleware))

export default store
